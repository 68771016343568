<template>
  <div class="register">
    <div class="register-title">新規登録</div>
    <div class="register-subtitle">
      <span>studyPackシステムアカウント作成のため、下記の情報をご入力ください。</span>
    </div>
    <el-form ref="registerForm" :model="form" class="app-form" :rules="rules">
      <el-form-item label="名前（漢字）" prop="username">
        <el-input v-model="form.username" autocomplete="off"
          placeholder="名前 漢字"
        ></el-input>
      </el-form-item>
      <el-form-item label="名前（カナ）" prop="usernameKana">
        <el-input v-model="form.usernameKana" autocomplete="off"
          placeholder="ナマエ カナ"
        ></el-input>
      </el-form-item>
      <el-form-item label="生年月日" prop="birthday">
        <el-date-picker
          v-model="form.birthday"
          type="date"
          placeholder="生年月日">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="性別" prop="sex">
        <el-radio v-model="form.sex" label="0">男性</el-radio>
        <el-radio v-model="form.sex" label="1">女性</el-radio>
      </el-form-item>
      <el-form-item label="メール" prop="mail">
        <el-input v-model="form.mail" autocomplete="off"
          placeholder="メール"
        ></el-input>
      </el-form-item>
      <el-form-item label="パスワード" prop="password">
        <el-input type="password" v-model="form.password" autocomplete="off"
          placeholder="パスワード"
        ></el-input>
      </el-form-item>
      <el-form-item label="パスワード（再入力）" prop="passwordConfirm">
        <div class="app-form-label">パスワード（再入力）</div>
        <el-input type="password" v-model="form.passwordConfirm" autocomplete="off"
          placeholder="パスワード（再入力）"
        ></el-input>
      </el-form-item>
      <el-form-item label="住所：〒" prop="addressCode">
        <el-input v-model="form.addressCode" autocomplete="off"
          placeholder="住所：〒"
        ></el-input>
      </el-form-item>
      <el-form-item label="住所：県" prop="addressCounty">
        <el-select v-model="form.addressCounty" style="width: 100%">
          <el-option
            v-for="item in mapSource"
            :key="item.id"
            :label="item.text"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="住所：市区町村" prop="addressCity">
        <el-input v-model="form.addressCity" autocomplete="off"
          placeholder="住所：市区町村"
        ></el-input>
      </el-form-item>
      <el-form-item label="住所：番地以下" prop="addressArea">
        <el-input v-model="form.addressArea" autocomplete="off"
          placeholder="住所：番地以下"
        ></el-input>
      </el-form-item>
      <el-form-item label="連絡：携帯" prop="mobile">
        <el-input v-model="form.mobile" autocomplete="off"
          placeholder="連絡：携帯"
        ></el-input>
      </el-form-item>
      <el-form-item label="連絡：電話" prop="phone">
        <el-input v-model="form.phone" autocomplete="off"
          placeholder="連絡：電話"
        ></el-input>
      </el-form-item>
      <el-form-item label="連絡：FAX" prop="fax">
        <el-input v-model="form.fax" autocomplete="off"
          placeholder="連絡：FAX"
        ></el-input>
      </el-form-item>
      <div class="register-docs">
        <router-link to="/rules">利用規約</router-link>
        <span>と</span>
        <router-link to="/inforules">プライバシーポリシー</router-link>
        <span>をお読みになり同意いただける場合にご登録ください。</span>
      </div>
      <el-form-item>
        <el-button class="studypack-button-primary" type="primary" @click="onSubmit">確認</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import constant from '@/utils/constant';
import tools from '@/utils/tools';

const { mapSource } = constant;
const { checkEmail, checkPassword } = tools;

export default {
  name: 'Register',
  data() {
    return {
      mapSource,
      form: {
        username: '',
        usernameKana: '',
        birthday: '',
        sex: '0',
        mail: '',
        password: '',
        passwordConfirm: '',
        addressCode: '',
        addressCounty: '',
        addressCity: '',
        addressArea: '',
        mobile: '',
        phone: '',
        fax: '',
      },
      rules: {
        username: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
        usernameKana: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
        birthday: [
          {
            type: 'date', required: true, message: '必須です。', trigger: 'blur',
          },
        ],
        sex: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
        mail: [
          { validator: checkEmail, trigger: 'blur' },
        ],
        password: [
          { required: true, message: '必須です。', trigger: 'blur' },
          { validator: checkPassword, trigger: 'blur' },
        ],
        passwordConfirm: [
          { validator: this.passwordConfirm, trigger: 'blur' },
        ],
        addressCode: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
        addressCounty: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
        addressCity: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
        addressArea: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
        fax: [
          { required: true, message: '必須です。', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    passwordConfirm(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    onSubmit() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          console.log('submit!');
          return true;
        }
        console.log('error submit!!');
        return false;
      });
    },
  },
};
</script>

<style lang="less">
.register {
  margin: 20px auto;
  width: 600px;

  .register-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  .register-subtitle {
    text-align: center;
    font-size: 18px;
    margin-bottom: 24px;
  }
  .register-docs {
    font-size: 16px;
    margin-bottom: 24px;
    line-height: 1.2;
  }
}
</style>
